import { require } from './../../utils/http'
//分享海报
function getImg() {
  return require('post', '/api/kapin_ee/share/v1/poster_list/')
}
//生成水印
function getwatermark(data) {
  return require('post', '/api/kapin_ee/share/v1/make_watermark/', data)
}
export { getImg, getwatermark }

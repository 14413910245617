<!-- 分享视频 -->
<template>
  <div class="wrap" v-loading="loading">
    <div v-if="isShow">
      <div class="play-margin" v-for="(item, index) in videoList" :key="index">
        <video
          width="190"
          :controls="is_controls"
          :poster="item.file_first"
          :id="`play${index}`"
        >
          <source type="video/mp4" :src="item.video_url" />
        </video>
        <button class="downLook" @click="down(item, index)">
          <i :class="{ 'el-icon-loading': isShui }"></i>
          生成水印
        </button>
      </div>
    </div>
    <p v-else class="kong">暂无视频</p>
    <!-- <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
    </div>-->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getImg, getwatermark } from '@/api/promotion'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      videoList: [],
      hint: '生成水印',
      isShow: false,
      lastTime: 10,
      isShui: false,
      index_video: 0,
      is_controls: true,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    down(item, index) {
      console.log(this.isShui, this.index_video)
      if (this.isShui || this.index_video > 0) {
        this.$message.warning('水印正在生成中，请稍后')
        return false
      }
      for (let i = 0; i < this.videoList.length; i++) {
        // document.getElementById('play' + i).src = this.videoList[
        //   i
        // ].video_url
        document.getElementById('play' + i).pause()
      }
      this.is_controls = false
      this.isShui = true
      this.index_video++
      // if (this.lastTime != 0 && this.lastTime != 10) return
      console.log(item)
      let name = sessionStorage.getItem('name')
      let place = sessionStorage.getItem('place')
      getwatermark({
        video_file_id: item.file_id,
        video_url: item.video_url,
        video_id: item.id,
        text1: place || '请先填写公司信息',
        text2: name || 'xxx',
        // text3: '我们欢迎你',
      }).then((res) => {
        if (res.code === 0) {
          let player = document.getElementById(`play${index}`)
          player.src = res.data.file_url
          this.isShui = false
          this.index_video = 0
          this.is_controls = true
          player.play()
          this.$message({
            message: res.msg,
            type: 'success',
          })
        } else {
          // this.lastTime = 10
          // let msg = res.msg + '请再次点击生成水印'
          // let a = this.$message({
          //   showClose: true,
          //   duration: '10000',
          //   message: msg + `(${this.lastTime}s)`,
          //   type: 'success',
          // })
          // let b = setInterval(() => {
          //   // console.log('进来了吗', this.lastTime)
          //   if (this.lastTime === 0) {
          //     clearInterval(b)
          //     b = null
          //     return
          //   } else {
          //     this.lastTime--
          //     a.message = msg + `(${this.lastTime}s)`
          //   }
          // }, 1000)
          this.$message({
            message: '水印生成失败',
            type: 'warning',
          })
        }
        // console.log(res)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    getImg().then((res) => {
      console.log(res)
      this.loading = false
      this.videoList = res.data.video_data
      this.videoList.length > 0 ? (this.isShow = true) : (this.isShow = false)
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  margin-top: 80px;
}
.play-margin {
  margin-right: 30px;
  margin-top: 20px;
  display: inline-block;
  width: 190px;
  height: 360px;
}
.pagination {
  width: 50%;
  margin: 20px auto 0;
}
.downLook {
  width: 190px;
  text-align: center;
  background-color: $main4_color;
  color: #fff;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
}
.kong {
  text-align: center;
  font-size: 14px;
  color: #aaaaaa;
}
</style>
